function NoPage() {

    return <div className={'pt-5'}>

        <div className={'col-md-8 col-12 m-auto p-5 mt-5'}>
            <h1>
                Error #404
            </h1>
            <p className={'mt-3 text-secondary'}>
                Page not found!
            </p>
            <a href="/" className={'btn btn-dark rounded-pill p-3 col-md-4 col-12 m-auto mt-5'}>
                Go Back
            </a>
        </div>

    </div>
}

export default NoPage;
