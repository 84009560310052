// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import HomePage from "./pages/home";
import PageTemplate from "./template/page";
import React, {Suspense, useEffect} from "react";
import {
    BrowserRouter,
} from "react-router-dom";
import {Route, Routes} from "react-router";
import NoPage from "./pages/nopage";
import AboutMePage from "./pages/about-me";
import CertificatesPage from "./pages/certificates";
import ScienceWorkPage from "./pages/science-work";
import LinksPage from "./pages/links";
import axios from "axios";

function App() {

    useEffect(() => {
        axios.post('https://api.lukyanchuk.com/api/v2/frontend/detected' + '?subDomain=yaroslav.lukyanchuk.com', [], {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }).then((response) => {
            console.log(response.data)
        });

    })

    return (
        <div className="App">
            <Suspense fallback="...loading">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<PageTemplate/>}>


                            <Route index element={<HomePage/>}/>

                            <Route path="/about" element={<AboutMePage/>}/>
                            <Route path="/certificates" element={<CertificatesPage/>}/>
                            <Route path="/science-work" element={<ScienceWorkPage/>}/>
                            <Route path="/links" element={<LinksPage/>}/>
                            <Route path="/business-card" element={<LinksPage/>}/>
                            <Route path="/b-card" element={<LinksPage/>}/>

                            <Route path="*" element={<NoPage/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

export default App;
